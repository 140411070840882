<script>
export default {
    
}
</script>

<template>
    <div class="close-button"></div>
</template>

<style scoped>
.close-button {
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.close-button::before,
.close-button::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 19px;
    width: 2px;
    height: 23px;
    background-color: white;
}


.close-button::before {
    transform: rotate(-45deg);
}

.close-button::after {
    transform: rotate(45deg);
}
</style>