<script>
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        leftLabel: {
            type: String,
            default: null
        },
        rightLabel: {
            type: String,
            default: null
        }
    },
    computed: {
        hasLabels() {
            return this.leftLabel || this.rightLabel;
        }
    },
    emits: ['update:modelValue'],
}
</script>

<template>
    <div class="withLabels" v-if="hasLabels">
        <span class="label" v-if="leftLabel" @click="$emit('update:modelValue', false)">{{leftLabel}}</span>
        <label class="toggle">
            <input type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)" />
            <span class="button"></span>
        </label>
        <span class="label" v-if="rightLabel" @click="$emit('update:modelValue', true)">{{rightLabel}}</span>
    </div>
    <label class="toggle" v-else>
        <input type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)" />
        <span class="button"></span>
    </label>
</template>

<style scoped>
.withLabels {
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 24px;
    cursor: default;
    margin: 10px;
}

span.label {
    cursor: default;
    display: inline-block;
    user-select: none;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

input {
    opacity: 0;
    height: 0;
    width: 0;
}

span.button {
    position: absolute;
    cursor: default;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-color: #0B1130;
}

span.button::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 5px;
    top: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 260ms;
}

input:checked + span::before {
    transform: translateX(30px);
}
</style>