<script>
import EtonForm from '@/components/eton/EtonForm.vue';
import EtonThankYou from '@/components/eton/EtonThankYou.vue';

const defaultLabels = {
    sizeRating: "Size",
    fabricRating:"Fabric",
    designRating:"Design",
    productRating: "Product",
    websiteRating:"Our website",
    shippingRating: "Shipping",
    packagingRating: "Packaging",
    ratings: {
        "1": "Bad",
        "2": "Not so good",
        "3": "Ok",
        "4": "Good",
        "5": "Very good"
    },
    yourHeight: "Your height",
    yourWeight: "Your weight",
    fullName: "Full name",
    thankYou: "Thank you",
    thisWasSent: "This was sent",
    sendReview: "Send review",
    measurementsTitle: "Help us help others find their perfect size",
    wasTheSizeRecommendationGood: "Was the size recommendation helpful?",
    yes: "Yes",
    no: "No",
    namePlaceholder: "Your name",
    formSubtitle: "Tell us your opinion",
    commentPlaceholder: "Write your comment here",
    addComment: "Add comment",
    errors: {
        weight: {
            low: "Weight too low",
            high: "Weight too high",
            lowBmi: "Weight is to low for the given height",
            highBmi: "Weight is to high for the given height",
        },
        height: {
            low: "Height too low",
            high: "Height too high",
            lowBmi: "Height is to high for the given weight",
            highBmi: "Height is to low for the given weight",
        },
        required: "Required field",
    },
    titles: {
        experience: "Shop Review",
        accessory: "Product Review",
        shirt: "Shirt Review",
    },
    backToStore: "Take me to Eton website",
    ratingStars: "{ratingStars} stars",
    beAnonymous: "I want my entry to be anonymous",
    review: "Review",
};

export default {
    components: {
        EtonForm,
        EtonThankYou,
    },
    data() {
        return {
            formData: null,
            submittedData: null
        }
    },
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    computed: {
        labels() {
            return {
                ...defaultLabels,
                ...(this.config.labels || {})
            };
        },
    },
    methods: {
        setFormData(formdata) {
            this.formData = formdata;
        },
        setSubmittedData(submittedData) {
            this.submittedData = submittedData;
        }
    }
}
</script>

<template>
    <div class="eton">
        <eton-form
            v-if="!submittedData"
            :language="config.language"
            :languageHint="config.languageHint"
            :labels="labels"
            @submitted-data:updated="setSubmittedData"
            @form-data:updated="setFormData"
            />
        <eton-thank-you
            v-if="submittedData"
            :labels="labels"
            :submitted-data="submittedData"
            :form-data="formData"
            />
    </div>
</template>

<style>
@import url(/eton/fonts.css);
.eton {
    height: 100%;
}

h1 {
    font-family: 'GaramondPremrPro';
    text-align: center;
    font-size: 35px;
    font-weight: normal;
    margin: 5px;
}

h2 {
    text-transform: uppercase;
    font-size: 13px;
    margin: 0 0 12px;
    padding: 0;
    font-family: 'Neutraface2Text';
    letter-spacing: 2px;
}

h3 {
    font-family: 'GaramondPremrPro';
    font-size: 17px;
    font-weight: bold;
    color: #0B1130;
    max-width: 180px;
    margin: 26px auto 16px;
}

header p {
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    margin: 0;
    letter-spacing: 1px;
    font-family: 'Neutraface2Text';
}
</style>