import { gql } from 'graphql-request';

export default {
    createReview: gql`
        mutation createReview(
            $itemId: String!
            $authorId: String
            $name: String
            $email: String
            $transactionId: String
            $languageHint: String
            $transactionSecret: String
            $designRating: RatingInputType
            $sizeRating: RatingInputType
            $fabricRating: RatingInputType
            $websiteRating: RatingInputType
            $shippingRating: RatingInputType
            $packagingRating: RatingInputType
            $productRating: RatingInputType
            $privateAuthor: Boolean
            $usedSizeRecommendation: Boolean
            $sizeRecommendationWasGood: Boolean
            $height: Decimal
            $weight: Decimal
            $country: String
            ) {
                createReview(
                    itemId: $itemId
                    authorId: $authorId
                    name: $name
                    email: $email
                    transactionId: $transactionId
                    transactionSecret: $transactionSecret
                    designRating: $designRating
                    sizeRating: $sizeRating
                    fabricRating: $fabricRating
                    websiteRating: $websiteRating
                    shippingRating: $shippingRating
                    packagingRating: $packagingRating
                    languageHint: $languageHint
                    productRating: $productRating
                    privateAuthor: $privateAuthor
                    height: $height
                    weight: $weight
                    country: $country
                    usedSizeRecommendation: $usedSizeRecommendation
                    sizeRecommendationWasGood: $sizeRecommendationWasGood
                ) {
                    id
                    secret
                }
            }
    `
}