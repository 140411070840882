<script>
import NumberInputWithUnit from '@/components/eton/NumberInputWithUnit.vue';
import { convertCmToInches, convertIncesToCm } from '@/utils/unit-converters.js';

export default {
    components: {
        NumberInputWithUnit,
    },
    data() {
        return {
            inch: 0
        };
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
    },
    created() {
        if (!this.modelValue) return;
        this.inch = Math.round(convertCmToInches(this.modelValue));
    },
    methods: {
        sanitizeInInput(inch) {
            this.inch = inch;
            if (this.inch < 0) {
                this.$nextTick(() => {
                    this.inch = 0;
                    this.$emit('update:modelValue', 0);
                })
            }
            else {
                this.$emit('update:modelValue', convertIncesToCm(this.inch));
            }
        },
    },
    emits: ['update:modelValue']
}
</script>

<template>
    <div class="in-input">
        <number-input-with-unit :modelValue="inch" @update:modelValue="sanitizeInInput" label="in" />
    </div>
</template>

<style scoped>
.in-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
</style>