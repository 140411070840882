<script>
import NumberInputWithUnit from '@/components/eton/NumberInputWithUnit.vue';
import { convertKgToStonesAndPounds, convertStonesAndPoundsToKg } from '@/utils/unit-converters.js';

export default {
    components: {
        NumberInputWithUnit,
    },
    data() {
        return {
            st: 0,
            lb: 0,
        };
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
    },
    created() {
        if (!this.modelValue) return;
        const stLb = convertKgToStonesAndPounds(this.modelValue);
        this.st = stLb.st;
        this.lb = Math.round(stLb.lb);
    },
    methods: {
        sanitizeLbInput(lb) {
            while(lb > 13) {
                this.st = this.st + 1;
                lb = lb - 14;
            }

            while(this.st && lb < 0) {
                this.st = this.st - 1;
                lb = lb + 14;
            }

            this.lb = lb;
            
            if (this.lb < 0) {
                this.$nextTick(() => {
                    this.lb = 0;
                    this.$emit('update:modelValue', convertStonesAndPoundsToKg(this.st, this.lb));
                })
            } else {
                this.$emit('update:modelValue', convertStonesAndPoundsToKg(this.st, this.lb));
            }
        },
        sanitizeStInput(st) {
            this.st = st;
            if (this.st < 0) {
                this.$nextTick(() => {
                    this.st = 0;
                    this.$emit('update:modelValue', convertStonesAndPoundsToKg(this.st, this.lb));
                })
            }
            else {
                this.$emit('update:modelValue', convertStonesAndPoundsToKg(this.st, this.lb));
            }
        }
    },
    emits: ['update:modelValue']
}
</script>

<template>
    <div class="st-lb-input">
        <number-input-with-unit class="st-lb-input__ft" :modelValue="st" @update:modelValue="sanitizeStInput" label="st" />
        <number-input-with-unit :modelValue="lb" @update:modelValue="sanitizeLbInput" label="lb" />
    </div>
</template>

<style scoped>
.st-lb-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.st-lb-input__ft {
    margin-right: 5px;
}
</style>