<script>
// eslint-disable-next-line
import { h } from 'vue';
import StarButton from './StarButton';

function buildStars(active, total, ctx) {
  const result = [];

  for(let i = 1; i <= total; i++) {
    result.push(<StarButton key={i} active={i <= active} onClick={() => { ctx.emit('update:active', i) }} />)
  }

  return result;
}

const StarsRating = (props, ctx) => {
  const { active, total } = props;
  let style = {
    width: '197px',
    display: 'inline-flex',
    justifyContent: 'space-between'
  };

  const items = buildStars(active, total, ctx);

  return (
    <div style={style}>
      {items}
    </div>
  )
};

StarsRating.props = {
    active: {
        type: Number,
        default: 0
    },
    total: {
        type: Number,
        default: 5,
    },
};

StarsRating.emits = ['update:active'];

export default StarsRating;
</script>