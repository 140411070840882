<script>
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
    },
    emits: ['update:modelValue'],
}
</script>

<template>
    <div class="eton-checkbox eton-checkbox--withLabel" v-if="label">
        <label class="eton-checkbox__container">
            <input class="eton-checkbox__input" type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)" />
            <span class="eton-checkbox__cross"></span>
        </label>
        <span class="eton-checkbox__label" @click="$emit('update:modelValue', !modelValue)">{{label}}</span>
    </div>
    <label class="eton-checkbox eton-checkbox__container" v-else>
        <input type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)" />
        <span class="eton-checkbox__cross"></span>
    </label>
</template>

<style scoped>
.eton-checkbox span {
    cursor: pointer;
}

.eton-checkbox--withLabel {
    display: flex;
    align-items: center;
    font-family: 'Neutraface2Text';
}

.eton-checkbox__container {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.eton-checkbox__input {
    opacity: 0;
    height: 0;
    width: 0;
}

.eton-checkbox__label {
    user-select: none;
    display: inline-block;
    margin-left: 10px;
}

.eton-checkbox__cross {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border: 1px solid #0B1130;
}

.eton-checkbox__cross::before,
.eton-checkbox__cross::after {
    position: absolute;
    content: "";
    background-color: #0B1130;
    display: none;
}

.eton-checkbox__cross::before {
    height: 1.5px;
    width: 7px;
    left: 3.5px;
    top: 13.5px;
    transform: rotate(51.34deg);
}

.eton-checkbox__cross::after {
    height: 1.5px;
    width: 15px;
    left: 5.5px;
    top: 10.5px;
    transform: rotate(126.87deg);
}

.eton-checkbox__input:checked + .eton-checkbox__cross::before,
.eton-checkbox__input:checked + .eton-checkbox__cross::after {
    display: block;
}
</style>