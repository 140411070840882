<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '',
        },
        max: {
            type: Number,
            default: 350,
        },
        errorMessage: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            text: this.modelValue
        };
    },
    emits: ['update:model-value'],
    computed: {
        charCount() {
            if (!this.modelValue) return 0;
            return this.modelValue.length;
        }
    },
    watch: {
        text(str) {
            this.text = str.slice(0, this.max);
            this.$emit('update:model-value', this.text);
        },
        modelValue(str) {
            this.text = str;
        }
    },
}
</script>

<template>
    <div class="text-area">
        <textarea :placeholder="placeholder" v-model="text">
        </textarea>
        <div class="char-count">
            {{charCount}}/{{max}}
        </div>
        <div class="text-area__error">{{errorMessage}}</div>
    </div>
</template>

<style>
    .text-area {
        position: relative;
    }

    textarea {
        border: 1px solid #E8E8E8;
        padding: 13px;
        width: calc(100% - 26px);
        min-height: 93px;
        font-family: 'Neutraface2Text';
    }

    textarea::placeholder {
        color: #878787;
    }

    .text-area__error {
        position: absolute;
        bottom: -4px;
        font-size: 14px;
        color: #F52214;
    }

    .char-count {
        color: #CDCDCD;
        font-size: 12px;
        text-align: right;
    }
</style>