<script>
import ToggleSwitch from '@/components/eton/ToggleSwitch.vue';
import NumberInputWithUnit from '@/components/eton/NumberInputWithUnit.vue';
import LbInput from '@/components/eton/LbInput.vue';
import StLbInput from '@/components/eton/StLbInput.vue';

export default {
    components: {
        ToggleSwitch,
        NumberInputWithUnit,
        LbInput,
        StLbInput,
    },
    data() {
        return {
            kg: 0,
        };
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
        useMetricSystem: {
            type: Boolean,
            required: true, 
        },
        label: {
            type: String,
            required: true,
        },
        market: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            default: null
        },
    },
    methods: {
        roundAndSet(kg) {
            this.kg = Math.round(kg);
            this.$emit('update:modelValue', this.kg);
        },
        changeUseMetricSystem(useMetricSystem) {
            this.$emit('update:useMetricSystem', useMetricSystem);
        }
    },
    computed: {
        marketUnits() {
            if (this.market === "us") {
                return ['lb', 'kg'];
            }

            if (this.market === "uk") {
                return ['st/lb', 'kg'];
            }

            return ['kg'];
        },
        hasUnitOptions() {
            return this.marketUnits.length > 1;
        },
        showLb() {
            return !this.useMetricSystem && this.marketUnits.indexOf('lb') !== -1;
        },
        showStLb() {
            return !this.useMetricSystem && this.marketUnits.indexOf('st/lb') !== -1;
        },
        showKg() {
            return this.useMetricSystem || !this.hasUnitOptions;
        }
    },
    emits: [
        'update:modelValue',
        'update:useMetricSystem',
    ]
}
</script>

<template>
    <div class="weight-input">
        <div class="weight-input__control">
            <span class="weight-input__label">{{label}}</span>
            <toggle-switch v-if="hasUnitOptions" :leftLabel="marketUnits[0]" :rightLabel="marketUnits[1]" :modelValue="useMetricSystem" @update:modelValue="changeUseMetricSystem" />
        </div>
        <div class="weight-input__inputs">
            <number-input-with-unit v-if="showKg" :modelValue="kg" @update:modelValue="roundAndSet" label="kg" />
            <lb-input v-if="showLb" :modelValue="kg" @update:modelValue="roundAndSet" />
            <st-lb-input v-if="showStLb" :modelValue="kg" @update:modelValue="roundAndSet" />
            <div v-if="errorMessage" class="weight-input__error">
                {{errorMessage}}
            </div>
        </div>
    </div>
</template>

<style>
.weight-input__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 14px;
    height: 44px;
}

.weight-input__label {
    color: #0B1130;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
}

.weight-input__inputs {
    display: flex;
    position: relative;
}

.weight-input__error
{
    position: absolute;
    bottom: 0;
    font-size: 14px;
    color:  #F52214;
    transform: translateY(calc(100% + 8px));
}
</style>