<script>
import ToggleSwitch from '@/components/eton/ToggleSwitch.vue';
import NumberInputWithUnit from '@/components/eton/NumberInputWithUnit.vue';
import FtInInput from './FtInInput.vue';
import InInput from './InInput.vue';

export default {
    components: {
        ToggleSwitch,
        NumberInputWithUnit,
        FtInInput,
        InInput,
    },
    data() {
        return {
            cm: 0,
        };
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
        useMetricSystem: {
            type: Boolean,
            required: true, 
        },
        market: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        errorMessage: {
            type: String,
            default: null
        },
    },
    computed: {
        marketUnits() {
            if (this.market === "us") {
                return ['ft/in', 'cm'];
            }

            if (this.market === "uk") {
                return ['in', 'cm'];
            }

            return ['cm'];
        },
        hasUnitOptions() {
            return this.marketUnits.length > 1;
        },
        showIn() {
            return !this.showCm && this.marketUnits.indexOf('in') !== -1;
        },
        showFtIn() {
            return !this.showCm && this.marketUnits.indexOf('ft/in') !== -1;
        },
        showCm() {
            return this.useMetricSystem || !this.hasUnitOptions;
        }
    },
    methods: {
        roundAndSet(cm) {
            this.cm = Math.round(cm);
            this.$emit('update:modelValue', this.cm);
        },
        changeUseMetricSystem(useMetricSystem) {
            this.$emit('update:useMetricSystem', useMetricSystem);
        },
    },
    emits: [
        'update:modelValue',
        'update:useMetricSystem',
    ]
}
</script>

<template>
    <div class="height-input">
        <div class="height-input__control">
            <span class="height-input__label">{{label}}</span>
            <toggle-switch v-if="hasUnitOptions" :leftLabel="marketUnits[0]" :rightLabel="marketUnits[1]" :modelValue="useMetricSystem" @update:modelValue="changeUseMetricSystem" />
        </div>
        <div class="height-input__inputs">
            <number-input-with-unit v-if="showCm" :modelValue="cm" @update:modelValue="roundAndSet" label="cm" />
            <in-input v-if="showIn" :modelValue="cm" @update:modelValue="roundAndSet" />
            <ft-in-input v-if="showFtIn" :modelValue="cm" @update:modelValue="roundAndSet" />
            <div v-if="errorMessage" class="height-input__error">
                {{errorMessage}}
            </div>
        </div>
    </div>
</template>

<style>
.height-input__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 14px;
    height: 44px;
}

.height-input__label {
    color: #0B1130;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
}

.height-input__inputs {
    display: flex;
    position: relative;
}

.height-input__error {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    color: #F52214;
    transform: translateY(calc(100% + 8px));
}
</style>