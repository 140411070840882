<script>
import CloseButton from '@/components/eton/CloseButton.vue';
import { ratingFieldSortFunction } from '@/utils/eton-field-sorter.js';

export default {
    components: {
        CloseButton,
    },
    inject: [
        'query',
        'tycka',
        'close'
    ],
    props: {
        formData: {
            type: Object,
            required: true,
        },
        submittedData: {
            type: Object,
            required: true,
        },
        labels: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sortedRatingFields() {
            var fieldsClone = this.formData.item.fields.filter(f => f.type === "Rating");
            fieldsClone.sort(ratingFieldSortFunction);
            return fieldsClone;
        },
        formTitle() {
            return this.labels.thankYou;
        },
        formSubtitle() {
            return this.labels.thisWasSent;
        }
    },
    methods: {
        getLabel(key) {
            return this.labels[key] || key;
        },
        getErrorLabel(key) {
            return this.labels.errors[key];
        },
        getRatingLabel(rating) {
            const ratingLabels = this.labels.ratings || {};
            return ratingLabels[rating.value * 5];
        },
        getRatingFor(rating) {
            const stars = this.submittedData[rating.name].value * 5;
            return this.getLabel('ratingStars').replace('{ratingStars}', `${stars}/5`);
        },
        getCommentFor(rating) {
            return this.submittedData[rating.name].comment;
        },

    }
}
</script>

<template>
    <div class="eton-thank-you">
        <header class="eton-thank-you__header">
            <h1>{{formTitle}}</h1>
            <p>{{formSubtitle}}</p>
            <close-button class="eton-thank-you__close-button" @click="close" />
        </header>
        <div>
            <div v-for="ratingField in sortedRatingFields" :key="ratingField.name" class="eton-thank-you__rating">
                <h2>{{getLabel(ratingField.name)}}</h2>
                <p class="eton-thank-you__stars-with-label"><span class="eton-thank-you__stars">{{getRatingFor(ratingField)}} - </span>{{getRatingLabel(submittedData[ratingField.name])}}</p>
                <p class="eton-thank-you__rating-comment" v-if="getCommentFor(ratingField)">{{getCommentFor(ratingField)}}</p>
            </div>
        </div>
        <a class="eton-thank-you__button" href="#" @click="close">{{getLabel("backToStore")}}</a>
    </div>
</template>

<style>
.eton-thank-you__header {
    height: 110px;
    width: 100%;
    color: #0B1130;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.eton-thank-you__close-button.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.eton-thank-you__close-button.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}
.eton-thank-you__rating {
    border-top: 1px solid #F7F7F7;
    padding: 23px 0;
}

.eton-thank-you__rating:last-child {
    border-bottom: 1px solid #F7F7F7;
}

.eton-thank-you__rating p {
    margin: 12px 0 0;
}

.eton-thank-you__stars {
    font-size: 16px;
    font-weight: bold;
    color: #0A1130;
    font-family: 'Neutraface2Text';
}

.eton-thank-you__stars-with-label {
    font-size: 15px;
    font-family: 'GaramondPremrPro';
    color: #858897;
    line-height: 16px;
    text-align: center;
    margin-bottom: 10px;
}

.eton-thank-you__rating-comment {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.eton-thank-you__close-button.close-button::before,
.eton-thank-you__close-button.close-button::after {
    background-color: #0B1130;
}

.eton-thank-you__rating h2 {
    text-align: center;
    font-size: 12px;
}

.eton-thank-you__button {
    line-height: 40px;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-family: 'Neutraface2Text';
    text-decoration: none;
    color: #0B1130;
    margin-top: 30px;
}
</style>