<script>
// eslint-disable-next-line
import { h } from 'vue'

const StarButton = (props) => {
    const color = props.active ? '#0B1130' : '#C2C3CB';

    return (
      <svg style="width: 28px" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3733 0L17.5718 9.84398H27.9224L19.5486 15.9279L22.7471 25.7719L14.3733 19.688L5.99951 25.7719L9.19801 15.9279L0.824219 9.84398H11.1748L14.3733 0Z" fill={color} />
      </svg>
    );
}

StarButton.props = {
    active: {
      type: Boolean,
      default: false,
    },
};

export default StarButton;
</script>
