<script>
export default {
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    methods: {
        selectAllText(e) {
            const value = e.target.value;
            try {
                e.target.setSelectionRange(0, value.length);
            }
            // eslint-disable-next-line
            catch(e){}
        }
    }
}
</script>

<template>
    <label class="number-input-with-unit">
        <input class="number-input-with-unit__input" type="text" :value="modelValue" @focus="selectAllText" @change="$emit('update:modelValue', +$event.target.value)">
        <span class="number-input-with-unit__label">{{label}}</span>
    </label>
</template>

<style scoped>
.number-input-with-unit {
    display: flex;
    border: 1px solid #E8E8E8;
    width: calc(100% - 22px);
    padding: 14px 10px;
    align-items: center;
    position: relative;
}

.number-input-with-unit__input {
    border: 0;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    width: calc(100% - calc(100% - 35px));
    padding: 0 calc(100% - 51px) 0 14px;
    font-size: 19px;
    font-family: 'Neutraface2Text';
    text-align: center;
}

.number-input-with-unit__label {
    font-size: 19px;
    color: #C2C3CB;
    pointer-events: none;
    z-index: 1;
    margin-left: calc(100% - calc(100% - 55px));
}
</style>
