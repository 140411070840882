const maxFieldIndex = 999;

const ratingFieldSortOrder = [
    "websiteRating",
    "shippingRating",
    "packagingRating",
    "designRating",
    "productRating",
    "sizeRating",
    "fabricRating",
];

export function ratingFieldSortFunction(a, b) {
    return indexOrMax(a) - indexOrMax(b);
}

function indexOrMax(name) {
    const indexResult = ratingFieldSortOrder.indexOf(name);
    if (indexResult === -1) return maxFieldIndex;
    return indexResult;
}