<script>
import StarsRating from '@/components/eton/StarsRating.vue';
import CommentButton from '@/components/eton/CommentButton.vue';
import CloseButton from '@/components/eton/CloseButton.vue';
import EtonCheckbox from '@/components/eton/EtonCheckbox.vue';
import TextArea from '@/components/eton/TextArea.vue';
import HeightInput from '@/components/eton/HeightInput.vue';
import WeightInput from '@/components/eton/WeightInput.vue';

import { scrollIntoView } from '@/utils/scroll-into-view';

import mutations from '@/forms/eton/mutations.js';
import { ratingFieldSortFunction } from '@/utils/eton-field-sorter.js';

const commentRequired = [
    "productRating",
    "fabricRating",
];

export default {
    components: {
        StarsRating,
        CommentButton,
        CloseButton,
        TextArea,
        EtonCheckbox,
        HeightInput,
        WeightInput,
    },
    inject: [
        'query',
        'tycka',
        'close',
    ],
    data() {
        return {
            formData: {},
            formDraft: {
                anonymous: false
            },
            invalidFields: [],
            expandedComments: {},
            useMetricSystem: false,
        }
    },
    props: {  
        labels: {
            type: Object,
            required: true,
        },
        language: {
            type: [String, null],
            default: null
        },
        languageHint: {
            type: [String, null],
            default: null,
        },
    },
    computed: {
        formTitle() {
            const title = this.labels.titles[this.itemType];
            return title || this.getLabel('review');
        },
        formSubtitle() {
            return this.labels.formSubtitle;
        },
        itemType() {
            const tags = this.formData && this.formData.item && this.formData.item.tags;
            if (!tags) return 'unknown';

            if (tags.indexOf('shirt') !== -1) return "shirt";
            if (tags.indexOf('accessory') !== -1) return "accessory";
            if (tags.indexOf('experience') !== -1) return "experience";

            return 'unknown';
        },
        shouldShowItem() {
            return this.itemType !== 'experience';
        },
        itemId() {
            return this.query.itemId;
        },
        transactionId() {
            return this.query.transactionId;
        },
        secret() {
            return this.query.secret;
        },
        usedSizeRecommendation() {
            for(let f of this.transactionCustomFields) {
                if (f.key === "usedSizeRecommendation") {
                    if (!f.value) return false;

                    for(let v of f.value.split(',')) {
                        const parts = v.split(':');
                        if (parts[0] === this.itemId) {
                            return !!parts[1];
                        }
                        else {
                            return false;
                        }
                    }

                    return false;
                }
            }

            return null;
        },
        market() {
            for(let f of this.transactionCustomFields) {
                if (f.key === "market") return f.value && f.value.toLowerCase() || null;
            }

            return null;
        },
        imageUrl() {
            if (!this.formData || !this.formData.item) return null;
            return this.formData.item.imageUrls[0];
        },
        itemName() {
            const langName = this.formData.item["name_" + this.language];
            if (langName) return langName;
            return this.formData.item.name;
        },
        fields() {
            if (!this.formData.item) return {};
            return this.formData.item.fields.reduce((p, c) => p[c.name] = c && p, {});
        },
        transactionCustomFields() {
            if (!this.formData || !this.formData.transaction) return [];
            return this.formData.transaction.customFields || []
        },
        transactionName() {
            for(let f of this.transactionCustomFields) {
                if (f.key === "name") return f.value;
            }

            return null;
        },
        transactionEmail() {
            for(let f of this.transactionCustomFields) {
                if (f.key === "email") return f.value;
            }

            return null;
        },
        authorId() {
            return this.formData &&
                this.formData.transaction &&
                this.formData.transaction.authorId;
        },
        sortedRatingFields() {
            if (!this.formData.item) return [];
            var fieldsClone = this.formData.item.fields.filter(f => f.type === "Rating");
            fieldsClone.sort(ratingFieldSortFunction);
            return fieldsClone;
        },
        isBodyMeasurementsInvalid() {
            return !this.possibleBmi ||
                !this.isWeightOk ||
                !this.isHeightOk;
        },
        bmi() {
            const { weight, height } = this.formDraft;
            if (!weight || !height) return 0;
            const heightInM = height / 100;
            return weight / (heightInM * heightInM);
        },
        possibleBmi() {
            return !this.isBmiTooLow &&
                !this.isBmiTooHigh;
        },
        isBmiTooLow() {
            const bmi = this.bmi;
            if (!bmi) return false;
            return bmi < 10;
        },
        isBmiTooHigh() {
            const bmi = this.bmi;
            if (!bmi) return false;
            return bmi > 106;
        },
        isWeightOk() {
            return !this.isWeightTooHigh &&
                !this.isWeightTooLow;
        },
        isWeightTooHigh() {
            const { weight } = this.formDraft;
            if (!weight) return false;

            return weight > 635;
        },
        isWeightTooLow() {
            const { weight } = this.formDraft;
            if (!weight) return false;

            return weight < 14;
        },
        isHeightOk() {
            return !this.isHeightTooHigh &&
                !this.isHeightTooLow;
        },
        isHeightTooHigh() {
            const { height } = this.formDraft;
            if (!height) return false;

            return height > 231;
        },
        isHeightTooLow() {
            const { height } = this.formDraft;
            if (!height) return false;

            return height < 54;
        },
        currentHeightErrorMessage() {
            if (this.isHeightTooHigh) return this.labels.errors.height.high;
            if (this.isHeightTooLow) return this.labels.errors.height.low;
            if (this.isBmiTooHigh) return this.labels.errors.height.highBmi;
            if (this.isBmiTooLow) return this.labels.errors.height.lowBmi;

            return null;
        },
        currentWeightErrorMessage() {
            if (this.isWeightTooHigh) return this.labels.errors.weight.high;
            if (this.isWeightTooLow) return this.labels.errors.weight.low;
            if (this.isBmiTooHigh) return this.labels.errors.weight.highBmi;
            if (this.isBmiTooLow) return this.labels.errors.weight.lowBmi;
            
            return null;
        }
    },
    async mounted() {
        const result = await this.tycka.client.request(this.tycka.getTyckaDataForTransaction(this.tycka.alternativeNames), {
            itemId: this.itemId,
            transactionId: this.transactionId,
            secret: this.secret,
        });
        this.formData = result;
        this.formDraft.name = this.transactionName;
        for(let f of this.sortedRatingFields) {
            if (this.isCommentRequired(f.name)) {
                this.expandComment(f.name);
            }
        }
    },
    watch: {
        formData(data) {
            this.$emit('form-data:updated', data);
        },
    },
    methods: {
        getLabel(key) {
            return this.labels[key] || key;
        },
        getErrorLabel(key) {
            return this.labels.errors[key];
        },
        getRatingLabel(ratingScore) {
            const ratingLabels = this.labels.ratings || {};
            return ratingLabels[ratingScore];
        },
        expandComment(key) {
            this.expandedComments[key] = true;
        },
        isCommentRequired(key) {
            return commentRequired.indexOf(key) !== -1;
        },
        isCommentExpanded(key) {
            return this.expandedComments[key];
        },
        isSizeField(key) {
            return key === 'sizeRating';
        },
        clearValidationFor(key) {
            this.invalidFields = this.invalidFields.filter(f => f !== key);
        },
        errorMessageFor(key) {
            if (this.invalidFields.indexOf(key) !== -1) {
                return this.labels.errors.required;
            }

            return null;
        },
        async sendReview() {
            const payload = {
                itemId: this.itemId,
                transactionId: this.transactionId,
                transactionSecret: this.secret,
                authorId: this.authorId,
                email: this.transactionEmail,
                privateAuthor: this.formDraft.anonymous || false,
                name: this.formDraft.anonymous ? this.transactionName : this.formDraft.name,
                usedSizeRecommendation: this.usedSizeRecommendation,
                country: this.market,
                languageHint: this.languageHint,
            }
            
            this.invalidFields = [];

            for(let f of this.sortedRatingFields) {
                const commentKey = `${f.name}_comment`;
                if (!this.formDraft[f.name]) {
                    this.invalidFields.push(f.name);
                    continue;
                }

                if (this.isCommentRequired(f.name) && !this.formDraft[commentKey]) {
                    this.invalidFields.push(commentKey);
                    continue;
                }

                payload[f.name] = {
                    value: this.formDraft[f.name] / 5,
                    comment: this.formDraft[commentKey] || null,
                };
            }

            if (!this.formDraft.anonymous && !this.formDraft.name) {
                this.invalidFields.push('name');
            }

            if (this.invalidFields.length || this.isBodyMeasurementsInvalid) {
                this.$nextTick(() => {
                    const form = document.querySelector('.eton-form');
                    const firstError = document.querySelector('.eton-form--error');
                    if (firstError) {
                        scrollIntoView(form, firstError);
                    }
                });
                return;
            }

            if (this.formDraft.height) {
                payload.height = this.formDraft.height;
            }

            if (this.formDraft.weight) {
                payload.weight = this.formDraft.weight;
            }

            if (this.usedSizeRecommendation &&
                this.formDraft.sizeRecommendationWasGood?.length) {
                payload.sizeRecommendationWasGood = !!parseInt(this.formDraft.sizeRecommendationWasGood);
            }

            await this.tycka.client.request(mutations.createReview, payload);
            this.$emit('submitted-data:updated', payload);
        }
    },
    emits: [
        'submitted-data:updated',
        'form-data:updated'
    ]
}
</script>

<template>
    <div class="eton-form">
        <header class="eton-form__header">
            <h1>{{formTitle}}</h1>
            <p>{{formSubtitle}}</p>
            <close-button class="eton-form__close" @click="close" />
        </header>
        <div class="item" v-if="imageUrl && shouldShowItem">
            <div class="name">{{itemName}}</div>
            <img class="eton-form__item-image" :src="imageUrl" />
        </div>
        <div class="rating-groups">
            <div v-for="ratingField in sortedRatingFields" :key="ratingField.name" class="rating-group" :class="{ 'eton-form--error': errorMessageFor(ratingField.name) || errorMessageFor(`${ratingField.name}_comment`) }">
                <h2>{{getLabel(ratingField.name)}}</h2>
                <stars-rating :active="formDraft[ratingField.name] || 0" @update:active="formDraft[ratingField.name] = $event; clearValidationFor(ratingField.name)" />
                <p class="rating-comment" v-if="getRatingLabel(formDraft[ratingField.name])">{{getRatingLabel(formDraft[ratingField.name])}}</p>
                <div class="comment-container" v-if="formDraft[ratingField.name]">
                    <comment-button class="comment-button" :label="getLabel('addComment')" v-if="!isCommentExpanded(ratingField.name)" @click="expandComment(ratingField.name)" />
                    <text-area
                        class="comment-input"
                        :placeholder="getLabel('commentPlaceholder')"
                        v-if="isCommentExpanded(ratingField.name)"
                        v-model="formDraft[`${ratingField.name}_comment`]"
                        :error-message="errorMessageFor(`${ratingField.name}_comment`)"
                        @change="clearValidationFor(`${ratingField.name}_comment`)" />
                </div>
                <div v-if="isSizeField(ratingField.name) && formDraft[ratingField.name] && !usedSizeRecommendation" class="additional-size-info" :class="{ 'eton-form--error': isBodyMeasurementsInvalid }">
                    <h3>{{getLabel("measurementsTitle")}}</h3>
                    <height-input v-model="formDraft.height" class="measurement" :use-metric-system="useMetricSystem" @update:use-metric-system="v => useMetricSystem = v" :market="market" :label="getLabel('yourHeight')" :error-message="currentHeightErrorMessage" />
                    <weight-input v-model="formDraft.weight" class="measurement" :use-metric-system="useMetricSystem" @update:use-metric-system="v => useMetricSystem = v" :market="market" :label="getLabel('yourWeight')" :error-message="currentWeightErrorMessage" />
                </div>
                <div v-if="isSizeField(ratingField.name) && formDraft[ratingField.name] && usedSizeRecommendation" class="additional-size-info" :class="{ 'eton-form--error': isBodyMeasurementsInvalid }">
                    <h3>{{getLabel("measurementsTitle")}}</h3>
                    {{ getLabel("wasTheSizeRecommendationGood") }}
                    <div class="eton-form__size-recommendation-section">
                        <label>
                            <span class="eton-form__radio-button" :class="{ 'eton-form__radio-button--active' : formDraft.sizeRecommendationWasGood == '1' }">
                                <svg v-if="formDraft.sizeRecommendationWasGood == '1'" aria-hidden="true" width="11" height="8" viewBox="0 0 14 10"><path d="M2.088 4.358l-.14-.14-.142.14-.947.94-.142.141.142.142 3.726 3.727.135.135.141-.129 7.94-7.226.155-.141-.148-.148-.94-.94-.134-.134-.141.126-6.859 6.154-2.646-2.647z" fill="white" stroke-width=".4"></path></svg>
                            </span>
                            <input type="radio" value="1" style="position: absolute;height: 0;width: 0;" v-model="formDraft.sizeRecommendationWasGood">
                            {{getLabel("yes")}}
                        </label>
                        <label>
                            <span class="eton-form__radio-button" :class="{ 'eton-form__radio-button--active' : formDraft.sizeRecommendationWasGood == '0' }">
                                <svg v-if="formDraft.sizeRecommendationWasGood == '0'" aria-hidden="true" width="11" height="8" viewBox="0 0 14 10"><path d="M2.088 4.358l-.14-.14-.142.14-.947.94-.142.141.142.142 3.726 3.727.135.135.141-.129 7.94-7.226.155-.141-.148-.148-.94-.94-.134-.134-.141.126-6.859 6.154-2.646-2.647z" fill="white" stroke-width=".4"></path></svg>
                            </span>
                            <input type="radio" value="0" style="position: absolute;height: 0;width: 0;" v-model="formDraft.sizeRecommendationWasGood">
                            {{getLabel("no")}}
                        </label>
                    </div>
                </div>
                <div v-if="errorMessageFor(ratingField.name)" class="rating-group__error">
                    {{ errorMessageFor(ratingField.name) }}
                </div>
            </div>
        </div>
        <div class="name-section" :class="{ 'eton-form--error': errorMessageFor('name') }">
            <eton-checkbox class="anonymous-checkbox" v-model="formDraft.anonymous" @change="clearValidationFor('name')" :label="getLabel('beAnonymous')" />
            <div class="name-entry" v-if="!formDraft.anonymous">
                <label class="name-label" for="fullName">{{getLabel("fullName")}}</label>
                <input class="name-input" id="fullName" type="text" :placeholder="getLabel('namePlaceholder')" v-model="formDraft.name" @input="clearValidationFor('name')" />
                <div v-if="errorMessageFor('name')" class="name-section__error">
                    {{ errorMessageFor('name') }}
                </div>
            </div>
        </div>
        <button @click="sendReview">{{getLabel("sendReview")}}</button>
    </div>
</template>

<style>
.eton-form__header {
    background-color: #0B1130;
    height: 110px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.eton-form__close.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.eton-form__radio-button {
    transition: background-color .08s linear;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background-color: #e8e8e8;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 5px;
}

.eton-form__radio-button--active {
    background-color: #0b1130;
}

.eton-form__size-recommendation-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}

.eton-form__size-recommendation-section label {
    display: flex;
}

.name-label {
    display: block;
    color: #0A1130;
    font-size: 16px;
    margin-bottom: 5px;
}

.name-input {
    width: calc(100% - 32px);
    padding: 7px 15px;
    border: 1px solid #E8E8E8;
    margin-bottom: 8px;
}

.name-section {
    text-align: left;
    margin: 20px;
}

.name-entry {
    position: relative;

}

.anonymous-checkbox {
    margin-bottom: 25px;
}

.additional-size-info {
    border-top: 1px solid #E8E8E8;
    margin-top: 20px;
}

.measurement {
    margin: 20px 0 30px;
}

.measurement:last-child {
    margin-bottom: 8px;
}

.eton-form button {
    color: white;
    background-color: #0B1130;
    width: calc(100% - 40px);
    font-size: 13px;
    padding: 13px;
    margin: 0 auto;
    text-transform: uppercase;
    border: 0;
    letter-spacing: .1em;
}

.rating-comment {
    color: #858897;
    font-family: 'GaramondPremrPro';
    font-size: 15px;
    margin: 10px 10px 15px;
}

.rating-groups {
    display: inline-block;
    width: 100%;
    background-color: #FAF9F7;
    margin-bottom: 15px;
}

.rating-group {
    text-align: center;
    margin: 10px;
    padding: 24px 10px;
    background-color: white;
    position: relative;
}

.rating-group__error {
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    font-size: 14px;
    color:  #F52214;
}

.name-section__error {
    font-size: 14px;
    color:  #F52214;
    position: absolute;
    left: 0;
    bottom: -14px;
}

.comment-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.comment-input {
    width: 100%;
}

.item {
    text-align: center;
    padding: 25px;
    background-color: white;
}

.item .name {
    font-size: 17px;
    padding-bottom: 20px;
    font-family: 'GaramondPremrPro';
}

.eton-form__item-image {
    height: 167px;
    width: 134px;
    text-align: center;
    object-fit: cover;
}

.eton-form {
    text-align: center;
    font-family: 'Neutraface2Text';
    padding-bottom: 20px;
    height: calc(100% - 20px);
    overflow: auto;
}

.eton-form textarea,
.eton-form input,
.eton-form select {
    font-family: 'Neutraface2Text';
}


</style>