<script>
import NumberInputWithUnit from '@/components/eton/NumberInputWithUnit.vue';
import { convertCmToFeetAndInches, convertFeetAndInchesToCm } from '@/utils/unit-converters.js';

export default {
    components: {
        NumberInputWithUnit,
    },
    data() {
        return {
            ft: 0,
            inch: 0,
        };
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
    },
    created() {
        if (!this.modelValue) return;
        const ftIn = convertCmToFeetAndInches(this.modelValue);
        this.ft = ftIn.ft;
        this.inch = ftIn.in;
    },
    methods: {
        sanitizeInchInput(inch) {
            while(inch > 11) {
                this.ft = this.ft + 1;
                inch = inch - 12;
            }

            while(this.ft && inch < 0) {
                this.ft = this.ft - 1;
                inch = inch + 12;
            }

            this.inch = inch;
            
            if (this.inch < 0) {
                this.$nextTick(() => {
                    this.inch = 0;
                    this.$emit('update:modelValue', convertFeetAndInchesToCm({ ft: this.ft, in: this.inch }));
                })
            } else {
                this.$emit('update:modelValue', convertFeetAndInchesToCm({ ft: this.ft, in: this.inch }));
            }
        },
        sanitizeFtInput(ft) {
            this.ft = ft;
            if (this.ft < 0) {
                this.$nextTick(() => {
                    this.ft = 0;
                    this.$emit('update:modelValue', convertFeetAndInchesToCm({ ft: this.ft, in: this.inch }));
                })
            }
            else {
                this.$emit('update:modelValue', convertFeetAndInchesToCm({ ft: this.ft, in: this.inch }));
            }
        }
    },
    emits: ['update:modelValue']
}
</script>

<template>
    <div class="ft-in-input">
        <number-input-with-unit class="ft-in-input__ft" :modelValue="ft" @update:modelValue="sanitizeFtInput" label="ft" />
        <number-input-with-unit :modelValue="inch" @update:modelValue="sanitizeInchInput" label="in" />
    </div>
</template>

<style scoped>
.ft-in-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.ft-in-input__ft {
    margin-right: 5px;
}
</style>